.custom-input {
  outline: 1px solid #7d8d96;
  border: none;
}

.custom-input-error {
  outline: 1px solid #d02929;
  border: none;
}

.custom-input-disabled {
  outline: 1px solid #7d8d96;
  border: none;
}

.custom-input::placeholder {
  color: #7d8d96;
}

.custom-input:hover {
  outline: 1px solid #000000;
}
