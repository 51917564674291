.select-big {
  outline: 1px solid #7d8d96 !important;
  border: none;
}

.select-big-disabled {
  outline: 1px solid #7d8d96 !important;
  border: none;
}

.select-big:hover {
  outline: 1px solid #000000 !important;
}

.select-big-popover .MuiPopover-paper {
  border-radius: 12px;
  box-shadow: 0px 0px 24px rgba(17, 67, 97, 0.12);
}
